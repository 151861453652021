// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/UIUtilsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.UIUtilsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import {SanitizedCss as goog_soy_data_SanitizedCss} from 'ts-closure-library/lib/soy/data';
import {SanitizedHtml as goog_soy_data_SanitizedHtml_} from 'ts-closure-library/lib/soy/data';
import {SanitizedHtmlAttribute as goog_soy_data_SanitizedHtmlAttribute} from 'ts-closure-library/lib/soy/data';
import * as google from 'ts-closure-library/lib/google';
import * as goog_html from 'ts-closure-library/lib/html/safehtml';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './LinkTemplate.soy.generated.js';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $alerts = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message sticky validation-errors" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:23"' : '') + '><i class="exclamation circle icon"></i></div><div class="ui warning message validation-warnings" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:26"' : '') + '><i class="exclamation triangle icon"></i></div><div class="ui success message sticky validation-infos" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:29"' : '') + '><i class="check circle icon"></i></div>');
};
export { $alerts as alerts };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $alerts.soyTemplateName = 'ts.commons.UIUtilsTemplate.alerts';
}


/**
 * @param {?$loadingSpinner.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $loadingSpinner = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $loadingSpinner$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} text
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $loadingSpinner$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinner']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.loadingSpinner']({text: text}, $ijData);
  }
  soy.assertParamType(text == null || typeof text === 'string', 'text', text, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<!-- If not $text fixes https://jira.cqse.eu/browse/TS-19152. --><div class="ui active inline text centered loader"' + (!text ? ' style="width: 100px !important;"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.loadingSpinner ts/commons/UIUtilsTemplate.soy:40"' : '') + '>' + (text ? soy.$$escapeHtml(text) : '') + '</div>');
};
export { $loadingSpinner as loadingSpinner };
export { $loadingSpinner$ as loadingSpinner$ };
/**
 * @typedef {{
 *  text?: (null|string|undefined),
 * }}
 */
$loadingSpinner.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $loadingSpinner.soyTemplateName = 'ts.commons.UIUtilsTemplate.loadingSpinner';
}


/**
 * @param {?$actionButton.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $actionButton = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $actionButton$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.className, opt_data.attributes, opt_data.style);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} className
 * @param {!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined=} attributes
 * @param {!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined=} style
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $actionButton$ = function($$areYouAnInternalCaller, $ijData, id, className, attributes, style) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionButton']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionButton']({id: id, className: className, attributes: attributes, style: style}, $ijData);
  }
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(className == null || typeof className === 'string', 'className', className, '@param', 'null|string|undefined');
  soy.assertParamType(attributes == null || goog_soy_data_SanitizedHtmlAttribute.isCompatibleWith(attributes), 'attributes', attributes, '@param', '!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(style == null || goog_soy_data_SanitizedCss.isCompatibleWith(style), 'style', style, '@param', '!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<a' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="ts-dropdown ' + (className ? soy.$$escapeHtmlAttribute(className) : '') + '"' + (attributes ? soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(attributes)) : '') + (style ? ' style="' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(style)) + '"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.actionButton ts/commons/UIUtilsTemplate.soy:58"' : '') + '><i class="ellipsis vertical link icon"></i></a>');
};
export { $actionButton as actionButton };
export { $actionButton$ as actionButton$ };
/**
 * @typedef {{
 *  id?: (null|string|undefined),
 *  className?: (null|string|undefined),
 *  attributes?: (!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  style?: (!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined),
 * }}
 */
$actionButton.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $actionButton.soyTemplateName = 'ts.commons.UIUtilsTemplate.actionButton';
}


/**
 * @param {?$actionDropdown.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $actionDropdown = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $actionDropdown$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.className, opt_data.attributes, opt_data.style);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} id
 * @param {null|string|undefined=} className
 * @param {!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined=} attributes
 * @param {!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined=} style
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $actionDropdown$ = function($$areYouAnInternalCaller, $ijData, id, className, attributes, style) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionDropdown']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.actionDropdown']({id: id, className: className, attributes: attributes, style: style}, $ijData);
  }
  soy.assertParamType(id == null || typeof id === 'string', 'id', id, '@param', 'null|string|undefined');
  soy.assertParamType(className == null || typeof className === 'string', 'className', className, '@param', 'null|string|undefined');
  soy.assertParamType(attributes == null || goog_soy_data_SanitizedHtmlAttribute.isCompatibleWith(attributes), 'attributes', attributes, '@param', '!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined');
  soy.assertParamType(style == null || goog_soy_data_SanitizedCss.isCompatibleWith(style), 'style', style, '@param', '!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="ts-dropdown ui dropdown ' + (className ? soy.$$escapeHtmlAttribute(className) : '') + '"' + (attributes ? soy.$$whitespaceHtmlAttributes(soy.$$filterHtmlAttributes(attributes)) : '') + (style ? ' style="' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(style)) + '"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.actionDropdown ts/commons/UIUtilsTemplate.soy:72"' : '') + '><i class="ellipsis vertical icon"></i></div>');
};
export { $actionDropdown as actionDropdown };
export { $actionDropdown$ as actionDropdown$ };
/**
 * @typedef {{
 *  id?: (null|string|undefined),
 *  className?: (null|string|undefined),
 *  attributes?: (!goog_soy_data_SanitizedHtmlAttribute|!soy.$$EMPTY_STRING_|null|string|undefined),
 *  style?: (!goog_html.SafeStyle|!goog_soy_data_SanitizedCss|!soy.$$EMPTY_STRING_|null|string|undefined),
 * }}
 */
$actionDropdown.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $actionDropdown.soyTemplateName = 'ts.commons.UIUtilsTemplate.actionDropdown';
}


/**
 * @param {!$dropdownAction.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $dropdownAction = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dropdownAction$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.title, opt_data.isLink);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} id
 * @param {string} title
 * @param {boolean} isLink
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $dropdownAction$ = function($$areYouAnInternalCaller, $ijData, id, title, isLink) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownAction']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.dropdownAction']({id: id, title: title, isLink: isLink}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(typeof isLink === 'boolean', 'isLink', isLink, '@param', 'boolean');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(isLink ? '<a' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="item" href="#"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.dropdownAction ts/commons/UIUtilsTemplate.soy:87"' : '') + '>' + soy.$$escapeHtml(title) + '</a>' : '<div' + (id ? ' id="' + soy.$$escapeHtmlAttribute(id) + '"' : '') + ' class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.dropdownAction ts/commons/UIUtilsTemplate.soy:89"' : '') + '>' + soy.$$escapeHtml(title) + '</div>');
};
export { $dropdownAction as dropdownAction };
export { $dropdownAction$ as dropdownAction$ };
/**
 * @typedef {{
 *  id: string,
 *  title: string,
 *  isLink: boolean,
 * }}
 */
$dropdownAction.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dropdownAction.soyTemplateName = 'ts.commons.UIUtilsTemplate.dropdownAction';
}


/**
 * @param {!$absoluteAndRelativeDateInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $absoluteAndRelativeDateInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $absoluteAndRelativeDateInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.name);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} name
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $absoluteAndRelativeDateInput$ = function($$areYouAnInternalCaller, $ijData, name) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']({name: name}, $ijData);
  }
  soy.assertParamType(typeof name === 'string', 'name', name, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div data-name="' + soy.$$escapeHtmlAttribute(name) + '" class="date-picker ' + soy.$$escapeHtmlAttribute(name) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput ts/commons/UIUtilsTemplate.soy:98"' : '') + '></div>');
};
export { $absoluteAndRelativeDateInput as absoluteAndRelativeDateInput };
export { $absoluteAndRelativeDateInput$ as absoluteAndRelativeDateInput$ };
/**
 * @typedef {{
 *  name: string,
 * }}
 */
$absoluteAndRelativeDateInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $absoluteAndRelativeDateInput.soyTemplateName = 'ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput';
}


/**
 * @param {!$csrfTokenInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $csrfTokenInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $csrfTokenInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.csrfToken);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} csrfToken
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $csrfTokenInput$ = function($$areYouAnInternalCaller, $ijData, csrfToken) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.csrfTokenInput']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.csrfTokenInput']({csrfToken: csrfToken}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<input type="hidden" name="csrfToken" value="' + soy.$$escapeHtmlAttribute(csrfToken) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.csrfTokenInput ts/commons/UIUtilsTemplate.soy:108"' : '') + '>');
};
export { $csrfTokenInput as csrfTokenInput };
export { $csrfTokenInput$ as csrfTokenInput$ };
/**
 * @typedef {{
 *  csrfToken: ?,
 * }}
 */
$csrfTokenInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $csrfTokenInput.soyTemplateName = 'ts.commons.UIUtilsTemplate.csrfTokenInput';
}


/**
 * @param {!$centeredDiv.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $centeredDiv = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.content, opt_data.useFullWidth, opt_data.fullWidth, opt_data.containerSegment);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string} content
 * @param {boolean|null|undefined=} useFullWidth
 * @param {null|string|undefined=} fullWidth
 * @param {boolean|null|undefined=} containerSegment
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $centeredDiv$ = function($$areYouAnInternalCaller, $ijData, content, useFullWidth, fullWidth, containerSegment) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']({content: content, useFullWidth: useFullWidth, fullWidth: fullWidth, containerSegment: containerSegment}, $ijData);
  }
  soy.assertParamType(goog_soy_data_SanitizedHtml_.isCompatibleWith(content), 'content', content, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string');
  soy.assertParamType(useFullWidth == null || typeof useFullWidth === 'boolean', 'useFullWidth', useFullWidth, '@param', 'boolean|null|undefined');
  soy.assertParamType(fullWidth == null || typeof fullWidth === 'string', 'fullWidth', fullWidth, '@param', 'null|string|undefined');
  soy.assertParamType(containerSegment == null || typeof containerSegment === 'boolean', 'containerSegment', containerSegment, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (!useFullWidth ? ' class="ui centered container ' + soy.$$escapeHtmlAttribute(containerSegment ? 'segment' : '') + '"' : '') + ' style="' + (!useFullWidth ? 'max-width: ' + (fullWidth ? soy.$$escapeHtmlAttribute(soy.$$filterCssValue(fullWidth)) : '900px') + '!important' : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.centeredDiv ts/commons/UIUtilsTemplate.soy:121"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $centeredDiv as centeredDiv };
export { $centeredDiv$ as centeredDiv$ };
/**
 * @typedef {{
 *  content: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml_|!soy.$$EMPTY_STRING_|string),
 *  useFullWidth?: (boolean|null|undefined),
 *  fullWidth?: (null|string|undefined),
 *  containerSegment?: (boolean|null|undefined),
 * }}
 */
$centeredDiv.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $centeredDiv.soyTemplateName = 'ts.commons.UIUtilsTemplate.centeredDiv';
}


/**
 * @param {!$singleOrMultipleEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $singleOrMultipleEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection, opt_data.textFilterEnabled);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @param {?} textFilterEnabled
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection, textFilterEnabled) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']({entries: entries, multiSelection: multiSelection, textFilterEnabled: textFilterEnabled}, $ijData);
  }
  let $output = (textFilterEnabled ? '<div class="ui small fluid input" style="padding-right: 15px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:140"' : '') + '><input class="entry-filter filterText" type="text" placeholder="type filter text..." style="width: 100%"></div><div class="ui divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:143"' : '') + '></div>' : '') + '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:145"' : '') + '><div class="grouped fields">';
  const entry813List = entries;
  const entry813ListLen = entry813List.length;
  for (let entry813Index = 0; entry813Index < entry813ListLen; entry813Index++) {
    const entry813Data = entry813List[entry813Index];
    $output += '<div class="field" id="entry-field-' + soy.$$escapeHtmlAttribute(entry813Data.name) + '"' + (entry813Data.description ? soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry813Data.description)) : '') + '><div class="ui ' + (!multiSelection ? 'radio' : '') + ' checkbox"><input class="entry-marker" data-entry-checkbox="true" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry813Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry813Data.name) : 'metric') + '"' + (entry813Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry813Data.name) + '" class="entry-marker-title">' + soy.$$escapeHtml(entry813Data.name) + '</label></div></div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $singleOrMultipleEntriesDialog as singleOrMultipleEntriesDialog };
export { $singleOrMultipleEntriesDialog$ as singleOrMultipleEntriesDialog$ };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 * }}
 */
$singleOrMultipleEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog';
}


/**
 * @param {!$hierarchicGroupedEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $hierarchicGroupedEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $hierarchicGroupedEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.groups, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} groups
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $hierarchicGroupedEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, groups, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']({groups: groups, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog ts/commons/UIUtilsTemplate.soy:184"' : '') + '>';
  const group906List = groups;
  const group906ListLen = group906List.length;
  for (let group906Index = 0; group906Index < group906ListLen; group906Index++) {
    const group906Data = group906List[group906Index];
    $output += '<li class="group-marker"><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="group-input-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(group906Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(group906Data.name) : 'metric') + '"' + (group906Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(group906Data.name) + '"><abbr>' + soy.$$escapeHtml(group906Data.name) + '</abbr></label></div><div style="padding-left:10px">' + $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, group906Data[/** @type {?} */ ('metrics')], multiSelection) + '</div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $hierarchicGroupedEntriesDialog as hierarchicGroupedEntriesDialog };
export { $hierarchicGroupedEntriesDialog$ as hierarchicGroupedEntriesDialog$ };
/**
 * @typedef {{
 *  groups: ?,
 *  multiSelection: ?,
 * }}
 */
$hierarchicGroupedEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $hierarchicGroupedEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog';
}


/**
 * @param {!$singleOrMultipleEntriesWithDisplayNameDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @private
 */
const $singleOrMultipleEntriesWithDisplayNameDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml_}
 * @private
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesWithDisplayNameDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']({entries: entries, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog ts/commons/UIUtilsTemplate.soy:218"' : '') + '>';
  const entry994List = entries;
  const entry994ListLen = entry994List.length;
  for (let entry994Index = 0; entry994Index < entry994ListLen; entry994Index++) {
    const entry994Data = entry994List[entry994Index];
    $output += '<li><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="entry-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry994Data.displayName) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry994Data.name) : 'metric') + '"' + (entry994Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry994Data.displayName) + '"><abbr' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry994Data.description)) + '>' + soy.$$escapeHtml(entry994Data.displayName) + '</abbr></label></div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 * }}
 */
$singleOrMultipleEntriesWithDisplayNameDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesWithDisplayNameDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog';
}


/**
 * @param {!$divWithIdWrapper.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $divWithIdWrapper = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithIdWrapper$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.content);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} id
 * @param {?} content
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $divWithIdWrapper$ = function($$areYouAnInternalCaller, $ijData, id, content) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']({id: id, content: content}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="' + soy.$$escapeHtmlAttribute(id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithIdWrapper ts/commons/UIUtilsTemplate.soy:247"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $divWithIdWrapper as divWithIdWrapper };
export { $divWithIdWrapper$ as divWithIdWrapper$ };
/**
 * @typedef {{
 *  id: string,
 *  content: ?,
 * }}
 */
$divWithIdWrapper.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithIdWrapper.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithIdWrapper';
}


/**
 * @param {!$divWithClass.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $divWithClass = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithClass$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.styleClass);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} styleClass
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $divWithClass$ = function($$areYouAnInternalCaller, $ijData, styleClass) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']({styleClass: styleClass}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="' + soy.$$escapeHtmlAttribute(styleClass) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithClass ts/commons/UIUtilsTemplate.soy:260"' : '') + '></div>');
};
export { $divWithClass as divWithClass };
export { $divWithClass$ as divWithClass$ };
/**
 * @typedef {{
 *  styleClass: ?,
 * }}
 */
$divWithClass.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithClass.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithClass';
}


/**
 * @param {?$reAnalysisIndicator.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $reAnalysisIndicator = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $reAnalysisIndicator$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.requiresNoReAnalysis);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?=} requiresNoReAnalysis
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $reAnalysisIndicator$ = function($$areYouAnInternalCaller, $ijData, requiresNoReAnalysis) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']({requiresNoReAnalysis: requiresNoReAnalysis}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Changing this parameter will ' + (requiresNoReAnalysis ? '*not* ' : '') + 'lead to a re-analysis of the project')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.reAnalysisIndicator ts/commons/UIUtilsTemplate.soy:274"' : '') + '><span class="reanalyze-indicator' + (requiresNoReAnalysis ? ' safe' : ' required') + '"></span></span>');
};
export { $reAnalysisIndicator as reAnalysisIndicator };
export { $reAnalysisIndicator$ as reAnalysisIndicator$ };
/**
 * @typedef {{
 *  requiresNoReAnalysis?: (?|undefined),
 * }}
 */
$reAnalysisIndicator.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $reAnalysisIndicator.soyTemplateName = 'ts.commons.UIUtilsTemplate.reAnalysisIndicator';
}


/**
 * @param {!$metricTableConfigDialogContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $metricTableConfigDialogContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent'](opt_data, $ijData);
  }
  /** @type {?} */
  const entries = opt_data.entries;
  /** @type {?} */
  const multiSelection = opt_data.multiSelection;
  /** @type {?} */
  const textFilterEnabled = opt_data.textFilterEnabled;
  /** @type {?} */
  const thresholdProfileNames = opt_data.thresholdProfileNames;
  /** @type {?} */
  const useAbbreviatedNumbers = opt_data.useAbbreviatedNumbers;
  /** @type {?} */
  const limitMetricsToProfile = opt_data.limitMetricsToProfile;
  /** @type {?} */
  const selectedThresholdProfile = opt_data.selectedThresholdProfile;
  let $output = '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:303"' : '') + '>Number Formatting</h5><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, large numbers will be render with a suffix, e.g. 3.5k')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:304"' : '') + '><input type="checkbox" id="checkbox-abbreviate-numbers"' + (useAbbreviatedNumbers ? ' checked="true"' : '') + '><label for="checkbox-abbreviate-numbers" class="checkbox">Abbreviate large numbers in table,\ne.g. <i>3.5k</i></label></div><h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:312"' : '') + '>Threshold Profile  ' + $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, 'A threshold profile tells Teamscale how to assess metric values') + '</h5>';
  if (soy.$$coerceToBoolean(thresholdProfileNames) && thresholdProfileNames.length > 0) {
    $output += '<select id="threshold-select" class="ui dropdown"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:316"' : '') + '>';
    const profileName1184List = thresholdProfileNames;
    const profileName1184ListLen = profileName1184List.length;
    for (let profileName1184Index = 0; profileName1184Index < profileName1184ListLen; profileName1184Index++) {
      const profileName1184Data = profileName1184List[profileName1184Index];
      $output += '<option' + (soy.$$equals(profileName1184Data, selectedThresholdProfile) ? ' selected' : '') + '>' + soy.$$escapeHtml(profileName1184Data) + '</option>';
    }
    $output += '</select><div class="ui marginless basic segment"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:321"' : '') + '><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, only metrics contained in the selected threshold profile will be shown.')) + '><input type="checkbox" id="checkbox-limit-to-profile"' + (limitMetricsToProfile ? ' checked="true"' : '') + '><label for="checkbox-limit-to-profile" class="checkbox">Only show metrics from this profile</label></div></div>';
  }
  $output += '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:333"' : '') + '>Metrics to show</h5>' + $singleOrMultipleEntriesDialog(/** @type {?} */ (opt_data), $ijData);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricTableConfigDialogContent as metricTableConfigDialogContent };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 *  thresholdProfileNames: ?,
 *  useAbbreviatedNumbers?: (?|undefined),
 *  limitMetricsToProfile?: (?|undefined),
 *  selectedThresholdProfile?: (?|undefined),
 * }}
 */
$metricTableConfigDialogContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricTableConfigDialogContent.soyTemplateName = 'ts.commons.UIUtilsTemplate.metricTableConfigDialogContent';
}


/**
 * @param {!$helpIcon.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $helpIcon = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $helpIcon$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, text)) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.helpIcon ts/commons/UIUtilsTemplate.soy:341"' : '') + '><i class="grey help circle icon"></i></span>');
};
export { $helpIcon as helpIcon };
export { $helpIcon$ as helpIcon$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$helpIcon.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $helpIcon.soyTemplateName = 'ts.commons.UIUtilsTemplate.helpIcon';
}


/**
 * @param {!$findingMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $findingMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.finding);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} finding
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $findingMessage$ = function($$areYouAnInternalCaller, $ijData, finding) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']({finding: finding}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml(finding.renderedMessage ? soy.$$escapeHtml(finding.renderedMessage) : soy.$$escapeHtml(finding.message));
};
export { $findingMessage as findingMessage };
export { $findingMessage$ as findingMessage$ };
/**
 * @typedef {{
 *  finding: ?,
 * }}
 */
$findingMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingMessage.soyTemplateName = 'ts.commons.UIUtilsTemplate.findingMessage';
}


/**
 * @param {!$tabContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $tabContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tabContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.tabNames, opt_data.tabMenuClassPrefix, opt_data.indexOfActiveTab, opt_data.tabAnnotations, opt_data.dontAddTabContentPanes);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} tabNames
 * @param {string} tabMenuClassPrefix
 * @param {null|number|undefined=} indexOfActiveTab
 * @param {!Array<null|number|string|undefined>|null|undefined=} tabAnnotations
 * @param {boolean|null|undefined=} dontAddTabContentPanes
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $tabContainer$ = function($$areYouAnInternalCaller, $ijData, tabNames, tabMenuClassPrefix, indexOfActiveTab, tabAnnotations, dontAddTabContentPanes) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']({tabNames: tabNames, tabMenuClassPrefix: tabMenuClassPrefix, indexOfActiveTab: indexOfActiveTab, tabAnnotations: tabAnnotations, dontAddTabContentPanes: dontAddTabContentPanes}, $ijData);
  }
  soy.assertParamType(Array.isArray(tabNames), 'tabNames', tabNames, '@param', '!Array<string>');
  soy.assertParamType(typeof tabMenuClassPrefix === 'string', 'tabMenuClassPrefix', tabMenuClassPrefix, '@param', 'string');
  soy.assertParamType(indexOfActiveTab == null || typeof indexOfActiveTab === 'number', 'indexOfActiveTab', indexOfActiveTab, '@param', 'null|number|undefined');
  soy.assertParamType(tabAnnotations == null || Array.isArray(tabAnnotations), 'tabAnnotations', tabAnnotations, '@param', '!Array<null|number|string|undefined>|null|undefined');
  soy.assertParamType(dontAddTabContentPanes == null || typeof dontAddTabContentPanes === 'boolean', 'dontAddTabContentPanes', dontAddTabContentPanes, '@param', 'boolean|null|undefined');
  let $output = '<div class="ui pointing secondary menu ' + soy.$$escapeHtmlAttribute(tabMenuClassPrefix) + '-tab-menu"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:372"' : '') + '>';
  const tabName1283List = tabNames;
  const tabName1283ListLen = tabName1283List.length;
  for (let tabName1283Index = 0; tabName1283Index < tabName1283ListLen; tabName1283Index++) {
    const tabName1283Data = tabName1283List[tabName1283Index];
    $output += '<div id="tab-item-' + soy.$$escapeHtmlAttribute(tabName1283Data) + '" class="tab item ' + (tabName1283Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" style="' + (soy.$$coerceToBoolean(tabAnnotations) && !tabAnnotations[/** @type {?} */ (tabName1283Index)] ? ' padding-bottom: 1.1em' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName1283Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName1283Data) + '">' + soy.$$escapeHtml(tabName1283Data) + (soy.$$coerceToBoolean(tabAnnotations) && soy.$$coerceToBoolean(tabAnnotations[/** @type {?} */ (tabName1283Index)]) ? $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, tabAnnotations[/** @type {?} */ (tabName1283Index)], true) : '') + '</div>';
  }
  $output += '<div class="invisible right item"></div></div>';
  if (!dontAddTabContentPanes) {
    const tabName1337List = tabNames;
    const tabName1337ListLen = tabName1337List.length;
    for (let tabName1337Index = 0; tabName1337Index < tabName1337ListLen; tabName1337Index++) {
      const tabName1337Data = tabName1337List[tabName1337Index];
      $output += '<div class="ui bottom attached tab tab-content ' + (tabName1337Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName1337Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName1337Data) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:399"' : '') + '></div>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $tabContainer as tabContainer };
export { $tabContainer$ as tabContainer$ };
/**
 * @typedef {{
 *  tabNames: !Array<string>,
 *  tabMenuClassPrefix: string,
 *  indexOfActiveTab?: (null|number|undefined),
 *  tabAnnotations?: (!Array<null|number|string|undefined>|null|undefined),
 *  dontAddTabContentPanes?: (boolean|null|undefined),
 * }}
 */
$tabContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tabContainer.soyTemplateName = 'ts.commons.UIUtilsTemplate.tabContainer';
}


/**
 * @param {!$circularLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 * @private
 */
const $circularLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text, opt_data.useSmallLabel);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number|string} text
 * @param {boolean|null|undefined=} useSmallLabel
 * @return {!goog_soy_data_SanitizedHtml_}
 * @private
 * @suppress {checkTypes}
 */
const $circularLabel$ = function($$areYouAnInternalCaller, $ijData, text, useSmallLabel) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']({text: text, useSmallLabel: useSmallLabel}, $ijData);
  }
  soy.assertParamType(typeof text === 'number' || typeof text === 'string', 'text', text, '@param', 'number|string');
  soy.assertParamType(useSmallLabel == null || typeof useSmallLabel === 'boolean', 'useSmallLabel', useSmallLabel, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span class="ui circular ' + (useSmallLabel ? 'small' : '') + ' light label" style="vertical-align: middle"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.circularLabel ts/commons/UIUtilsTemplate.soy:411"' : '') + '>' + soy.$$escapeHtml(text) + '</span>');
};
/**
 * @typedef {{
 *  text: (number|string),
 *  useSmallLabel?: (boolean|null|undefined),
 * }}
 */
$circularLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $circularLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.circularLabel';
}


/**
 * @param {!$tooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 */
const $tooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 * @suppress {checkTypes}
 */
const $tooltip$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtmlAttribute(text ? 'title="' + soy.$$escapeHtmlAttribute(text) + '"' : '');
};
export { $tooltip as tooltip };
export { $tooltip$ as tooltip$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$tooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tooltip.soyTemplateName = 'ts.commons.UIUtilsTemplate.tooltip';
}


/**
 * @param {!$baselineEndDateInformation.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $baselineEndDateInformation = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $baselineEndDateInformation$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.info);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}} info
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $baselineEndDateInformation$ = function($$areYouAnInternalCaller, $ijData, info) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.baselineEndDateInformation']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.baselineEndDateInformation']({info: info}, $ijData);
  }
  soy.assertParamType(google.isObject(info), 'info', info, '@param', '{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<abbr title="' + soy.$$escapeHtmlAttribute(info.baselineDescription) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.baselineEndDateInformation ts/commons/UIUtilsTemplate.soy:433"' : '') + '>' + soy.$$escapeHtml(info.baselineShortText) + '</abbr> \u2013 <abbr title="' + soy.$$escapeHtmlAttribute(info.endDescription) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.baselineEndDateInformation ts/commons/UIUtilsTemplate.soy:433"' : '') + '>' + soy.$$escapeHtml(info.endShortText) + '</abbr>');
};
export { $baselineEndDateInformation as baselineEndDateInformation };
export { $baselineEndDateInformation$ as baselineEndDateInformation$ };
/**
 * @typedef {{
 *  info: {baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,},
 * }}
 */
$baselineEndDateInformation.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselineEndDateInformation.soyTemplateName = 'ts.commons.UIUtilsTemplate.baselineEndDateInformation';
}


/**
 * @param {!$textWithLineBreaks.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $textWithLineBreaks = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $textWithLineBreaks$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.textLines);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} textLines
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $textWithLineBreaks$ = function($$areYouAnInternalCaller, $ijData, textLines) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']({textLines: textLines}, $ijData);
  }
  soy.assertParamType(Array.isArray(textLines), 'textLines', textLines, '@param', '!Array<string>');
  let $output = '';
  const textLine1407List = textLines;
  const textLine1407ListLen = textLine1407List.length;
  for (let textLine1407Index = 0; textLine1407Index < textLine1407ListLen; textLine1407Index++) {
    const textLine1407Data = textLine1407List[textLine1407Index];
    $output += (textLine1407Index > 0 ? '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.textWithLineBreaks ts/commons/UIUtilsTemplate.soy:443"' : '') + '>' : '') + soy.$$escapeHtml(textLine1407Data);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $textWithLineBreaks as textWithLineBreaks };
export { $textWithLineBreaks$ as textWithLineBreaks$ };
/**
 * @typedef {{
 *  textLines: !Array<string>,
 * }}
 */
$textWithLineBreaks.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $textWithLineBreaks.soyTemplateName = 'ts.commons.UIUtilsTemplate.textWithLineBreaks';
}


/**
 * @param {!$inputSelect.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $inputSelect = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputSelect$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.selectId, opt_data.selectItems, opt_data.selectedItem, opt_data.includeEmptyOption);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} selectId
 * @param {!Array<string>} selectItems
 * @param {null|string|undefined=} selectedItem
 * @param {boolean|null|undefined=} includeEmptyOption
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $inputSelect$ = function($$areYouAnInternalCaller, $ijData, selectId, selectItems, selectedItem, includeEmptyOption) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']({selectId: selectId, selectItems: selectItems, selectedItem: selectedItem, includeEmptyOption: includeEmptyOption}, $ijData);
  }
  soy.assertParamType(typeof selectId === 'string', 'selectId', selectId, '@param', 'string');
  soy.assertParamType(Array.isArray(selectItems), 'selectItems', selectItems, '@param', '!Array<string>');
  soy.assertParamType(selectedItem == null || typeof selectedItem === 'string', 'selectedItem', selectedItem, '@param', 'null|string|undefined');
  soy.assertParamType(includeEmptyOption == null || typeof includeEmptyOption === 'boolean', 'includeEmptyOption', includeEmptyOption, '@param', 'boolean|null|undefined');
  let $output = '<select class="ui dropdown" id="' + soy.$$escapeHtmlAttribute(selectId) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.inputSelect ts/commons/UIUtilsTemplate.soy:462"' : '') + '>' + (includeEmptyOption ? '<option value="" selected="selected">- Please select -</option>' : '');
  const item1439List = selectItems;
  const item1439ListLen = item1439List.length;
  for (let item1439Index = 0; item1439Index < item1439ListLen; item1439Index++) {
    const item1439Data = item1439List[item1439Index];
    $output += '<option' + (!includeEmptyOption && soy.$$equals(item1439Data, selectedItem) ? ' selected="selected"' : '') + '>' + soy.$$escapeHtml(item1439Data) + '</option>';
  }
  $output += '</select>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $inputSelect as inputSelect };
export { $inputSelect$ as inputSelect$ };
/**
 * @typedef {{
 *  selectId: string,
 *  selectItems: !Array<string>,
 *  selectedItem?: (null|string|undefined),
 *  includeEmptyOption?: (boolean|null|undefined),
 * }}
 */
$inputSelect.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputSelect.soyTemplateName = 'ts.commons.UIUtilsTemplate.inputSelect';
}


/**
 * @param {!$simulinkSignalDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $simulinkSignalDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkSignalDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.source, opt_data.target, opt_data.signals, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} source
 * @param {?} target
 * @param {!Array<?>} signals
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $simulinkSignalDialog$ = function($$areYouAnInternalCaller, $ijData, source, target, signals, maxDialogContentHeight, maxDialogContentWidth) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']({source: source, target: target, signals: signals, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(Array.isArray(signals), 'signals', signals, '@param', '!Array<?>');
  let $output = '';
  if (signals) {
    $output += '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:489"' : '') + '><table class="ui compact striped selectable table"><thead><th><span>' + soy.$$escapeHtml(source) + ' <i class="long arrow alternate right icon"></i> ' + soy.$$escapeHtml(target) + '</span></th><thead>';
    const signal1501List = signals;
    const signal1501ListLen = signal1501List.length;
    for (let signal1501Index = 0; signal1501Index < signal1501ListLen; signal1501Index++) {
      const signal1501Data = signal1501List[signal1501Index];
      $output += '<tr><td width="100%" style="font-weight: 600">' + soy.$$escapeHtml(signal1501Data) + '</td></tr>';
    }
    $output += '</table></div>';
  } else {
    $output += '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:509"' : '') + '><p>There is no signal information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkSignalDialog as simulinkSignalDialog };
export { $simulinkSignalDialog$ as simulinkSignalDialog$ };
/**
 * @typedef {{
 *  source: ?,
 *  target: ?,
 *  signals: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 * }}
 */
$simulinkSignalDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkSignalDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.simulinkSignalDialog';
}


/**
 * @param {!$page.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $page = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $page$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.caption, opt_data.description, opt_data.buttons, opt_data.fullWidth, opt_data.fullWidthValue, opt_data.captionPrefix, opt_data.captionLabel, opt_data.headerClass, opt_data.headerButtonsClass, opt_data.segmentBorder);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} caption
 * @param {null|string|undefined=} description
 * @param {!Array<?>|null|undefined=} buttons
 * @param {?=} fullWidth
 * @param {?=} fullWidthValue
 * @param {null|number|string|undefined=} captionPrefix
 * @param {null|string|undefined=} captionLabel
 * @param {null|string|undefined=} headerClass
 * @param {null|string|undefined=} headerButtonsClass
 * @param {boolean|null|undefined=} segmentBorder
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $page$ = function($$areYouAnInternalCaller, $ijData, caption, description, buttons, fullWidth, fullWidthValue, captionPrefix, captionLabel, headerClass, headerButtonsClass, segmentBorder) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']({caption: caption, description: description, buttons: buttons, fullWidth: fullWidth, fullWidthValue: fullWidthValue, captionPrefix: captionPrefix, captionLabel: captionLabel, headerClass: headerClass, headerButtonsClass: headerButtonsClass, segmentBorder: segmentBorder}, $ijData);
  }
  soy.assertParamType(typeof caption === 'string', 'caption', caption, '@param', 'string');
  soy.assertParamType(description == null || typeof description === 'string', 'description', description, '@param', 'null|string|undefined');
  soy.assertParamType(buttons == null || Array.isArray(buttons), 'buttons', buttons, '@param', '!Array<?>|null|undefined');
  soy.assertParamType(captionPrefix == null || typeof captionPrefix === 'number' || typeof captionPrefix === 'string', 'captionPrefix', captionPrefix, '@param', 'null|number|string|undefined');
  soy.assertParamType(captionLabel == null || typeof captionLabel === 'string', 'captionLabel', captionLabel, '@param', 'null|string|undefined');
  soy.assertParamType(headerClass == null || typeof headerClass === 'string', 'headerClass', headerClass, '@param', 'null|string|undefined');
  soy.assertParamType(headerButtonsClass == null || typeof headerButtonsClass === 'string', 'headerButtonsClass', headerButtonsClass, '@param', 'null|string|undefined');
  soy.assertParamType(segmentBorder == null || typeof segmentBorder === 'boolean', 'segmentBorder', segmentBorder, '@param', 'boolean|null|undefined');
  let param1545 = '<div class="page-header"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:549"' : '') + '><h1 class="ts-headline ' + soy.$$escapeHtmlAttribute(headerClass != null ? headerClass : '') + '">' + (captionPrefix ? '<span class="ts-headline-prefix">' + soy.$$escapeHtml(captionPrefix) + '</span>' : '') + soy.$$escapeHtml(caption) + '  ' + (captionLabel ? '<span><span class="ui circular label">' + soy.$$escapeHtml(captionLabel) + '</span></span>' : '') + '</h1>';
  if (soy.$$coerceToBoolean(buttons) && buttons.length > 0) {
    param1545 += '<div class="header-buttons ' + soy.$$escapeHtmlAttribute(headerButtonsClass != null ? headerButtonsClass : '') + '">';
    const button1599List = buttons;
    const button1599ListLen = button1599List.length;
    for (let button1599Index = 0; button1599Index < button1599ListLen; button1599Index++) {
      const button1599Data = button1599List[button1599Index];
      param1545 += !button1599Data.href ? '<button id="' + soy.$$escapeHtmlAttribute(button1599Data.id) + '" class="ui button ' + (button1599Data.icon ? 'icon ' : '') + (button1599Data.classParams ? soy.$$escapeHtmlAttribute(button1599Data.classParams) : '') + ' ' + (button1599Data.primary ? 'primary' : '') + '"' + (button1599Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button1599Data.tooltip) + '"' : '') + '><i' + (button1599Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button1599Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button1599Data.icon) + '"></i> ' + soy.$$escapeHtml(button1599Data.title) + '</button>' : '<a id="' + soy.$$escapeHtmlAttribute(button1599Data.id) + '" class="ui button ' + (button1599Data.icon ? 'icon ' : '') + (button1599Data.classParams ? soy.$$escapeHtmlAttribute(button1599Data.classParams) : '') + ' ' + (button1599Data.primary ? 'primary' : '') + '"' + (button1599Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button1599Data.tooltip) + '"' : '') + ' href=' + soy.$$escapeHtmlAttributeNospace(soy.$$filterNormalizeUri(button1599Data.href)) + (button1599Data.openInNewTab ? ' target="_blank"' : '') + '><i' + (button1599Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button1599Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button1599Data.icon) + '"></i> ' + soy.$$escapeHtml(button1599Data.title) + '</a>';
    }
    param1545 += '</div>';
  }
  param1545 += '</div>' + (description ? '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:579"' : '') + '>' + soy.$$escapeHtml(description) + '</div>' : '') + '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:581"' : '') + '>' + $alerts(null, $ijData) + '</div>';
  let $output = '' + $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks(param1545), fullWidth, fullWidthValue, segmentBorder);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $page as page };
export { $page$ as page$ };
/**
 * @typedef {{
 *  caption: string,
 *  description?: (null|string|undefined),
 *  buttons?: (!Array<?>|null|undefined),
 *  fullWidth?: (?|undefined),
 *  fullWidthValue?: (?|undefined),
 *  captionPrefix?: (null|number|string|undefined),
 *  captionLabel?: (null|string|undefined),
 *  headerClass?: (null|string|undefined),
 *  headerButtonsClass?: (null|string|undefined),
 *  segmentBorder?: (boolean|null|undefined),
 * }}
 */
$page.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $page.soyTemplateName = 'ts.commons.UIUtilsTemplate.page';
}


/**
 * @param {?$titleDownloadButton.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $titleDownloadButton = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $titleDownloadButton$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.title, opt_data.treemap);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|string|undefined=} title
 * @param {boolean|null|undefined=} treemap
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $titleDownloadButton$ = function($$areYouAnInternalCaller, $ijData, title, treemap) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.titleDownloadButton']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.titleDownloadButton']({title: title, treemap: treemap}, $ijData);
  }
  soy.assertParamType(title == null || typeof title === 'string', 'title', title, '@param', 'null|string|undefined');
  soy.assertParamType(treemap == null || typeof treemap === 'boolean', 'treemap', treemap, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="' + soy.$$escapeHtmlAttribute(treemap ? 'treemap-buttons' : '') + ' pull-left"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.titleDownloadButton ts/commons/UIUtilsTemplate.soy:600"' : '') + '><button class="button-download compact ui basic icon button" title="' + (!title ? 'Download' : soy.$$escapeHtmlAttribute(title)) + '"><i class="arrow alternate circle down outline icon"></i></button></div>');
};
export { $titleDownloadButton as titleDownloadButton };
export { $titleDownloadButton$ as titleDownloadButton$ };
/**
 * @typedef {{
 *  title?: (null|string|undefined),
 *  treemap?: (boolean|null|undefined),
 * }}
 */
$titleDownloadButton.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $titleDownloadButton.soyTemplateName = 'ts.commons.UIUtilsTemplate.titleDownloadButton';
}


/**
 * @param {!$issueStatusLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml_}
 */
const $issueStatusLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueStatusLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.issue, opt_data.size, opt_data.useBasicStyle);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} issue
 * @param {?=} size
 * @param {?=} useBasicStyle
 * @return {!goog_soy_data_SanitizedHtml_}
 * @suppress {checkTypes}
 */
const $issueStatusLabel$ = function($$areYouAnInternalCaller, $ijData, issue, size, useBasicStyle) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']({issue: issue, size: size, useBasicStyle: useBasicStyle}, $ijData);
  }
  let $output = '';
  let labelColor__soy1759 = '';
  const $tmp = issue.status;
  switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
    case 'Open':
    case 'open':
    case 'unresolved':
    case 'New':
    case 'Draft':
      labelColor__soy1759 += ' blue';
      break;
    case 'In Review':
    case 'In review':
    case 'Reviewed':
    case 'reviewed':
    case 'Review':
    case 'review':
    case 'Yellow':
    case 'yellow':
    case 'In Progress':
    case 'In progress':
    case 'In development':
    case 'In Development':
      labelColor__soy1759 += ' orange';
      break;
    case 'Closed':
    case 'closed':
    case 'resolved':
    case 'Resolved':
    case 'done':
    case 'Done':
    case 'Merged':
    case 'Approved':
      labelColor__soy1759 += ' green';
      break;
    case 'Rejected':
      labelColor__soy1759 += ' red';
      break;
    default:
      labelColor__soy1759 += ' grey';
  }
  $output += !soy.$$equals(issue.status, '') ? '<span class="ui label ' + (useBasicStyle ? 'basic' : '') + ' ' + (size != null ? soy.$$escapeHtmlAttribute(size) : 'medium') + soy.$$escapeHtmlAttribute(labelColor__soy1759) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.issueStatusLabel ts/commons/UIUtilsTemplate.soy:631"' : '') + '>' + soy.$$escapeHtml(issue.status) + '</span>' : '';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $issueStatusLabel as issueStatusLabel };
export { $issueStatusLabel$ as issueStatusLabel$ };
/**
 * @typedef {{
 *  issue: ?,
 *  size?: (?|undefined),
 *  useBasicStyle?: (?|undefined),
 * }}
 */
$issueStatusLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueStatusLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.issueStatusLabel';
}
