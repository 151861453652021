// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/quality_control/tasks/TasksPerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.quality_control.tasks.TasksPerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$taskSelectionDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $taskSelectionDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $taskSelectionDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.tasks, opt_data.enableAddTaskButton);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<?>} tasks
 * @param {boolean|null|undefined=} enableAddTaskButton
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $taskSelectionDialog$ = function($$areYouAnInternalCaller, $ijData, tasks, enableAddTaskButton) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.quality_control.tasks.TasksPerspectiveTemplate.taskSelectionDialog']) {
    return soy.$$stubsMap['ts.perspectives.quality_control.tasks.TasksPerspectiveTemplate.taskSelectionDialog']({tasks: tasks, enableAddTaskButton: enableAddTaskButton}, $ijData);
  }
  soy.assertParamType(Array.isArray(tasks), 'tasks', tasks, '@param', '!Array<?>');
  soy.assertParamType(enableAddTaskButton == null || typeof enableAddTaskButton === 'boolean', 'enableAddTaskButton', enableAddTaskButton, '@param', 'boolean|null|undefined');
  let $output = '<div style="height: 400px; overflow-y: auto;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.quality_control.tasks.TasksPerspectiveTemplate.taskSelectionDialog ts/perspectives/quality_control/tasks/TasksPerspectiveTemplate.soy:15"' : '') + '>';
  const tasksExist__soy24252 = tasks.length > 0;
  $output += enableAddTaskButton ? '<p><button id="add-task" class="compact ui button"><i class="icon tasks big-font"></i> New Task</button>' + (tasksExist__soy24252 ? ' or choose an existing task:' : '') + '</p>' : '';
  if (tasksExist__soy24252) {
    $output += '<table id="task-table" class="ui striped unstackable borderless sortable framed selectable table"><thead><tr><th id="sort-by-id"><b>ID</b></th><th id="sort-by-subject"><b>Subject</b></th><th id="sort-by-created"><b>Created</b></th></tr></thead><tbody>';
    const task24346List = tasks;
    const task24346ListLen = task24346List.length;
    for (let task24346Index = 0; task24346Index < task24346ListLen; task24346Index++) {
      const task24346Data = task24346List[task24346Index];
      $output += '<tr class="task-row" style="cursor: pointer;"><td>' + soy.$$escapeHtml(task24346Data.id) + '</td><td>' + (soy.$$coerceToBoolean(task24346Data) && soy.$$coerceToBoolean(task24346Data.renderedSubject) ? soy.$$escapeHtml(task24346Data.renderedSubject) : '') + '</td><td><div style="display:none;">' + soy.$$escapeHtml(task24346Data.created) + '</div>' + soy.$$escapeHtml(task24346Data.createdFormatted) + '</td></tr>';
    }
    $output += '</tbody></table>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $taskSelectionDialog as taskSelectionDialog };
export { $taskSelectionDialog$ as taskSelectionDialog$ };
/**
 * @typedef {{
 *  tasks: !Array<?>,
 *  enableAddTaskButton?: (boolean|null|undefined),
 * }}
 */
$taskSelectionDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $taskSelectionDialog.soyTemplateName = 'ts.perspectives.quality_control.tasks.TasksPerspectiveTemplate.taskSelectionDialog';
}
